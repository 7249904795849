import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'
import { Link, useLocation } from 'react-router-dom'
import NavbarOffcanvas from './NavbarOffcanvas'
import Menu from '../../assets/icons/header/menu.svg'
import { CLASSES } from '../../assets/styles/classes'
import './header.css'

function HomeHeader() {
  const [showMenu, setShowMenu] = React.useState(false)
  const location = useLocation()

  React.useEffect(() => {
    if (showMenu) {
      var dropdown = document.querySelector('.user-menu-btn')
      const handleShowMenu = (event) => {
        console.log('clicked outside user menu...')
        var isClickInside = dropdown?.contains(event.target)
        if (!isClickInside) {
          setShowMenu(false)
        }
      }
      document.addEventListener('click', handleShowMenu)
      return () => {
        document.addEventListener('click', handleShowMenu)
      }
    }
  }, [showMenu])

  return (
    <>
      <Navbar
        // sticky='top'
        fixed='top'
        collapseOnSelect
        expand='lg'
        style={{
          background:
            location.pathname === '/'
              ? 'linear-gradient(0deg, rgba(102, 148, 97, 0.392) 7.04%, rgba(59, 81, 57, 0.98) 93.14%)'
              : '#699D63',
        }}
        className='navbar py-2'
      >
        <Container fluid>
          <Container className='position-relative'>
            <div className={CLASSES.content_between}>
              <Link className='navbar-link text-white' to={'/'}>
                {/* <Image style={{ width: 230 }} src={Logo} /> */}
                <h3>Logo</h3>
              </Link>
              
              <div>
                <div className='d-lg-none'>
                  <Navbar.Toggle aria-controls='responsive-navbar-nav'>
                    <Image style={{ minWidth: 24, width: '12%' }} src={Menu} />
                  </Navbar.Toggle>
                </div>
                <NavbarOffcanvas />
              </div>
            </div>
          </Container>
        </Container>
      </Navbar>
    </>
  )
}

export default HomeHeader
