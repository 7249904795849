import Container from 'react-bootstrap/Container'

export default function TermsOfUSe() {
  return (
    <Container className='py-5'>
      <h1 className='mb-4'>Terms of uses</h1>
      <div
        className='user-setting-panel page-margin termsofuse pt_shadow'
        style={{ marginBottom: 30 }}
      >
        <h3>
          <strong>
            <span style={{ fontSize: 20 }}>Last Updated: March 3, 2023</span>
          </strong>
        </h3>
        <p style={{ lineHeight: 1.5 }}>
          <span
            style={{ fontSize: 18, fontFamily: 'arial, helvetica, sans-serif' }}
          >
            Welcome to UploadTheTruth! which is provided by UploadTheTruth (“
            <strong>UploadTheTruth</strong>”, “<strong>we</strong>” or “
            <strong>us</strong>”). For purposes of these Terms, “
            <strong>you</strong>” and “<strong>your</strong>” means you as the
            user of the Services.
          </span>
        </p>
        <p style={{ lineHeight: 1.5 }}>
          <span
            style={{ fontSize: 18, fontFamily: 'arial, helvetica, sans-serif' }}
          >
            Your use of the UploadTheTruth Service implies your acceptance of
            these Terms of Use. The Terms form a legally binding agreement
            between you and us. Please take the time to read them carefully.
          </span>
        </p>
        <h2 id='legalPages--toc-header-2' data-toc-id='toc-header-2'>
          <span style={{ fontSize: 18 }}>
            <strong>Changes to the Terms</strong>
          </span>
        </h2>
        <p>
          <span style={{ fontSize: 18 }}>
            We amend these Terms from time to time, for instance when we update
            the functionality of our Services, when there are regulatory
            changes. We will use commercially reasonable efforts to generally
            notify all users of any material changes to these Terms, such as
            through a notice on our Platform, however, you should look at the
            Terms regularly to check for such changes. We will also update the
            “Last Updated” date at the top of these Terms, which reflect the
            effective date of such Terms. Your continued access or use of the
            Services after the date of the new Terms constitutes your acceptance
            of the new Terms. If you do not agree to the new Terms, you must
            stop accessing or using the Services.
          </span>
        </p>
        <h2 id='legalPages--toc-header-3' data-toc-id='toc-header-3'>
          <span style={{ fontSize: 18 }}>
            <strong>Your Account with Us</strong>
          </span>
        </h2>
        <p>
          <span style={{ fontSize: 18 }}>
            To access or use some of our Services, you must create an account
            with us. When you create this account, you must provide accurate and
            up-to-date information. It is important that you maintain and
            promptly update your details and any other information you provide
            to us, to keep such information current and complete.
          </span>
        </p>
        <p>
          <span style={{ fontSize: 18 }}>
            It is important that you keep your account password confidential and
            that you do not disclose it to any third party. 
          </span>
        </p>
        <p>
          <span style={{ fontSize: 18 }}>
            You agree that you are solely responsible (to us and to others) for
            the activity that occurs under your account.
          </span>
        </p>
        <p>
          <span style={{ fontSize: 18 }}>
            We reserve the right to disable your user account at any time,
            including if you have failed to comply with any of the provisions of
            these Terms, or if activities occur on your account which, in our
            sole discretion, would or might cause damage to or impair the
            Services or infringe or violate any third party rights, or violate
            any applicable laws or regulations.
          </span>
        </p>
        <p>
          <span style={{ fontSize: 18 }}>
            If you no longer want to use our Services again, and would like your
            account deleted, you can easily delete the account from setting
            sections. Once you choose to delete your account, you will not be
            able to reactivate your account or retrieve any of the content or
            information you have added.
          </span>
        </p>
        <p style={{ lineHeight: 1.5 }}>
          <strong>
            <span
              style={{
                fontSize: 18,
                fontFamily: 'arial, helvetica, sans-serif',
              }}
            >
              Our Service
            </span>
          </strong>
        </p>
        <p style={{ lineHeight: 1.5 }}>
          <span
            style={{ fontSize: 18, fontFamily: 'arial, helvetica, sans-serif' }}
          >
            The Service allows you to discover the truth, watch, share truth
            videos (180 seconds), share skills (30 seconds), and writting
            articles, provides a forum for people to connect, inform, and
            inspire others across the globe.
          </span>
        </p>
        <p style={{ lineHeight: 1.5 }}>
          <span style={{ fontSize: 18 }}>
            <strong>Who may use the Service?</strong>
          </span>
        </p>
        <p>
          <span style={{ fontSize: 18 }}>
            Children of all ages may use the service if permitted by a parent or
            legal guardian. 
          </span>
        </p>
        <p>
          <span style={{ fontSize: 18 }}>
            If you are considered a minor in your country, you represent that
            you have your parent or guardian’s permission to use the Service.
            Please have them read this Agreement with you.
          </span>
        </p>
        <p>
          <span style={{ fontSize: 18 }}>
            If you are a parent or legal guardian of a minor in your country, by
            allowing your child to use the Service, you are subject to the terms
            of this Agreement and responsible for your child’s activity on the
            Service.  
          </span>
          <span style={{ fontSize: 18 }}>
            If you are using the Service on behalf of a company or organisation,
            you represent that you have authority to act on behalf of that
            entity, and that such entity accepts this Agreement.
          </span>
        </p>
        <div className='common_div_style'>
          <h3 className='mt-4'>
            <span style={{ fontSize: 18 }}>Your Commitments</span>
          </h3>
          <p className=''>
            <span style={{ fontSize: 18 }}>
              Providing a safe and secure Service for a diverse community
              requires that we all do our part. In return for our commitment to
              provide our Services, we require you to make some commitments to
              us. Please note that you will solely bear the costs and
              consequences of any actions taken by you on the Kurkuri Platform
              (including any violation of these Terms) along with the
              commitments given by you below. By using our Services, you agree
              and acknowledge the following:
            </span>
          </p>
          <div>
            <ul>
              <li>
                <span style={{ fontSize: 14 }}>
                  You may not post nude, partially nude, or sexually suggestive
                  photos.
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ fontSize: 14 }}>
                  You are responsible for any activity that occurs under your
                  screen name.
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ fontSize: 14 }}>
                  You are responsible for keeping your password secure.
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ fontSize: 14 }}>
                  You must not abuse, harass, threaten, impersonate or
                  intimidate other UploadTheTruth users.
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ fontSize: 14 }}>
                  You may not use the UploadTheTruth service for any illegal or
                  unauthorized purpose. International users agree to comply with
                  all local laws regarding online conduct and acceptable
                  content.
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ fontSize: 14 }}>
                  You are solely responsible for your conduct and any data,
                  text, information, screen names, graphics, photos, profiles,
                  Content that you submit, post, and display on the
                  UploadTheTruth service.
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ fontSize: 14 }}>
                  You must not modify, adapt or hack UploadTheTruth or modify
                  another website so as to falsely imply that it is associated
                  with UploadTheTruth.
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ fontSize: 14 }}>
                  You must not access UploadTheTruth's private API by any other
                  means other than the UploadTheTruth application itself.
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ fontSize: 14 }}>
                  You must not create or submit unwanted email or comments to
                  any UploadTheTruth members ("Spam").
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ fontSize: 14 }}>
                  You must not use web URLs in your name without prior written
                  consent from UploadTheTruth.
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ fontSize: 14 }}>
                  You must not transmit any worms or viruses or any code of a
                  destructive nature.
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ fontSize: 14 }}>
                  You must not, in the use of UploadTheTruth, violate any laws
                  in your jurisdiction (including but not limited to copyright
                  laws).
                  <br />
                  <br />
                </span>
              </li>
              <li>
                <span style={{ fontSize: 14 }}>
                  We have implemented measures to ensure that our Platform is
                  secure. However, there is no guarantee that our Platform is
                  immune to hacking and virus attacks. You will not allow any
                  person to use your phone number.
                  <br /> 
                </span>
              </li>
              <li>
                <span style={{ fontSize: 14 }}>
                  Violation of any of these agreements will result in the
                  termination of your UploadTheTruth account. While
                  UploadTheTruth prohibits such conduct and content on its site,
                  you understand and agree that UploadTheTruth cannot be
                  responsible for the Content posted on its web site and you
                  nonetheless may be exposed to such materials and that you use
                  the UploadTheTruth service at your own risk.
                </span>
              </li>
            </ul>
            <p> </p>
          </div>
        </div>
      </div>
    </Container>
  )
}
