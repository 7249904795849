import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import ListGroup from 'react-bootstrap/ListGroup'
import Image from 'react-bootstrap/Image'

import { Link } from "react-router-dom";

import Logo from '../../assets/icons/common/logo.png';
import Facebook from '../../assets/icons/footer/facebook.svg';
import GooglePlay from '../../assets/icons/footer/google-play.png';
import Youtube from '../../assets/icons/common/youtube.svg';

// import Logo from '../../assets/icons/footer/logo.svg';
// import Facebook from '../../assets/icons/footer/facebook-white.svg';
import Instagram from '../../assets/icons/footer/instragam-white.svg';
import Phone from '../../assets/icons/header/phone.svg';
// import Youtube from '../../assets/icons/common/youtube-white.svg';
import Message from '../../assets/icons/header/message.svg';

function Footer() {

  const onClick = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  const backgroundImageStyle = {
    // backgroundImage: `url(${Tree})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#495C2D',
    backgroundPosition: 'center',
    width: '100%',
    color: 'white'
    // height: '400px', // Set the height as needed
  };


  return (
    <footer style={{marginTop: 'auto', ...backgroundImageStyle}} className="w-100">
      <Container className="py-3 ">
        <Row className='justify-content-center'>
          <Col md={6} lg={3} className="my-3">
            <Link onClick={onClick} className="" to={'/'}>
                {/* <Image style={{width: 187}} src={Logo}/> */}
                <h2 style={{width: 250}} className='text-white border text-center py-1 pb-2'>Logo</h2>
            </Link>
            <div className='my-1 mt-3'>22/1 Block-B(3rd Floor) Babar Road.</div>
            <div className='text-bold my-1'>Mohammadpur, Dhaka -1207</div>
            <div className='my-1'>
              <a className='text-white' style={{textDecorationLine: 'none'}}  target="_blank">
                <Image src={Phone}/>
                <span className='ms-2'>+8801731358773</span>
              </a>
            </div>
            <div className='my-1'>
              <a className='text-white' style={{textDecorationLine: 'none'}} target="_blank">
                <Image src={Message}/>
                <span className='ms-2'>actualgreeen@gmail.com</span>
              </a>
            </div>
            <div className='my-1'>
              <a className='text-white' style={{textDecorationLine: 'none'}} target="_blank">
                <Image src={Message}/>
                <span className='ms-2'>info@actualgreeen.com.bd</span>
              </a>
            </div>
          </Col>
         
          <Col md={6} lg={3} className="my-3">
            <div>
              <h5>ABOUT ACTUAL GREEN</h5>
              <ListGroup.Item className="py-1">  
                  <Link onClick={onClick} className="footer-link text-white" to={'/contact'}>Contact us</Link>
              </ListGroup.Item>
              <ListGroup.Item className="py-1">  
                  <Link onClick={onClick} className="footer-link text-white" to={'/help'}>Terms & Condition</Link>
              </ListGroup.Item>
              <ListGroup.Item className="py-1">  
                  <Link onClick={onClick} className="footer-link text-white" to={'/about'}>Privacy & Policy</Link>
              </ListGroup.Item>
              </div>
          </Col>
          <Col md={6} lg={3} className="my-3">
            <div>
              <h5>DOWNLOAD OUR ANDROID APP</h5>
              <ListGroup.Item className="my-2"> 
                {/* <Link onClick={onClick} style={{backgroundColor: 'black', width: 180}} className={CLASSES.content_center + "footer-link text-white border rounded py-1"} to={'/gallery'}>
                    <i style={{fontSize: 30}} class="fa-brands fa-google-play"></i>
                    <div className='ms-2'>
                      <div style={{fontSize: 12}}>GET IT ON</div>
                      <div style={{fontSize: 20, marginTop: - 8}}>Google Play</div>
                    </div>
                </Link> */}
                <Link onClick={onClick} style={{marginLeft: - 10}}  to={'/gallery'}>
                  <Image style={{width: 187}} src={GooglePlay}/>
                </Link>
              </ListGroup.Item>

                <h5>FOLLOW US ON FACEBOOK</h5>
                <ListGroup.Item className="py-1"> 
                    <Link onClick={onClick} className="footer-link text-white" to={'/gallery'}>
                        <i style={{fontSize: 40}} class="fa-brands fa-square-facebook"></i>
                    </Link>
                </ListGroup.Item>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
