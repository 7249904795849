import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'
import BannerImage from '../../../assets/images/home/banner-2.png'

import { CLASSES } from '../../../assets/styles/classes'

function AdBanner() {
  const backgroundImageStyle = {
    // backgroundImage: `url(${Tree})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    // height: '400px', // Set the height as needed
  }

  return <Image width={'100%'} src={BannerImage} />
}

export default AdBanner
