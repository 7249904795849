import React from 'react'
import {
  Row,
  Col,
  Container,
  Form,
  Image,
  Button,
  InputGroup,
} from 'react-bootstrap'
import moment from 'moment'
import { CLASSES } from '../../../assets/styles/classes'
import FevIcon from '../../../assets/icons/header/love.svg'
import ShareIcon from '../../../assets/icons/common/share.svg'
import DropDownIcon from '../../../assets/icons/common/arrow-down-bold.svg'

import Phone from '../../../assets/icons/common/phone-black.svg'
import Message from '../../../assets/icons/common/message.svg'

// import { USERS_2 } from '../../../utils/faker'
import DropDownX from '../../../components/common/DropDownX'
import { useLocation, useNavigate } from 'react-router-dom'
import HorizontalNavbar from '../components/HorizontalNavbar'

import ProductCard from '../../../components/common/ProductCard'
import ProductZoomView from '../../../components/common/ProductZoomView'

export default function ProductView() {
  const navigate = useNavigate()
  const state  = useLocation().state || {}
  React.useEffect(() => {
    if (!state.id) {
      navigate('/products')
    } else {
      window.scrollTo({top: 0})
    }
  }, [state])

  return (
    <Container className='p-4 py-2 pt-5 mt-2'>
      <div className='mt-5 text-bold'>
        Home / Products
      </div>
      <Row className='mb-5'>
        <Col md={6} className='my-1 my-md-2 my-lg-3 p-1 p-md-2 p-lg-3'>
          <ProductZoomView
            images={state.images}
            thumbnail={state.thumbnail}
          />
          {/* <Image src={} /> */}
        </Col>
        <Col md={6} className='my-1 my-md-2 my-lg-3 p-1 p-md-2 p-lg-3'>
          <div className='p-3 bg-white position-relative'>
            <h4>{state.name}</h4>
            <p className='mt-2 dangerouslySetInnerHTML'  dangerouslySetInnerHTML={{ __html: state.description }}  />
            <div className='mt-4 text-bold'>Additional Information</div>
            <hr className='mt-1'/>
            <div><span className='text-bold'>Price:</span> {state.price} TK</div>
            <div><span className='text-bold'>Weight:</span> {state.weight}</div>
            <div><span className='text-bold'>Code:</span> {state.code}</div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
