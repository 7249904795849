import Container from 'react-bootstrap/Container'

function AboutUS() {
  return (
    <Container fluid style={{background: 'linear-gradient(0deg, rgba(14, 135, 66, 0) 0%, rgba(7, 168, 76, 0.1) 100%)'}}>
      <Container className='py-5 mt-n5'>
        <h2 className='text-center text-success'>About Us</h2>
        <p className='text-center mt-4'>
          In publishing and graphic design, Lorem ipsum is a placeholder text
          commonly used to demonstrate the visual form of a document or a
          typeface without relying on meaningful content. Lorem ipsum may be
          used as a placeholder before the final copy is available.In publishing
          and graphic design, Lorem ipsum is a placeholder text commonly used to
          demonstrate the visual form of a document or a typeface without
          relying on meaningful content. Lorem ipsum may be used as a
          placeholder before the final copy is available.In publishing and
          graphic design, Lorem ipsum is a placeholder text commonly used to
          demonstrate the visual form of a document or a typeface without
          relying on meaningful content. Lorem ipsum may be used as a
          placeholder before the final copy is available.
        </p>
      </Container>
    </Container>
  )
}

export default AboutUS
