import { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Carousel from 'react-bootstrap/Carousel'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import moment from 'moment'
import MainBanner from '../../../assets/images/home/banner.jpg'
import BannerImage from '../../../assets/images/home/ad-slide-tree.png'
import ControlLeftIcon from '../../../assets/icons/common/control-left-white.svg'
import ControlRightIcon from '../../../assets/icons/common/control-right-white.svg'
import { CLASSES } from '../../../assets/styles/classes'
import { Rating } from './Rating'

function SliderBanner({ data = [] }) {
  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex)
  }

  return (
    <div className='position-relative'>
      <Carousel
        // activeIndex={index}
        hidden={false}
        indicators={false}
        onSlide={handleSelect}
        onSelect={handleSelect}
        nextIcon={
          <div
            style={{
              backgroundColor: '#F6F6F6',
              width: 70,
              height: 70,
              borderRadius: '50%',
            }}
            className={CLASSES.content_center}
          >
            <Image
              style={{ width: 40, height: 40 }}
              className='pointer'
              src={ControlRightIcon}
            />
          </div>
        }
        prevIcon={
          <div
            style={{
              backgroundColor: '#F6F6F6',
              width: 70,
              height: 70,
              borderRadius: '50%',
            }}
            className={CLASSES.content_center}
          >
            <Image
              style={{ width: 40, height: 40 }}
              className='pointer'
              src={ControlLeftIcon}
            />
          </div>
        }
      >
        {data.map((review, i) => (
          <Carousel.Item
            key={review.id}
            className='position-relative'
            style={{ height: 700 }}
          >
            {/* <Image style={{width: '100%', height: 557}} src={MainBanner}  /> */}
            <div
              className='d-flex flex-column justify-content-center'
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                zIndex: 1,
              }}
            >
              <Container className='mb-5'>
                <Row>
                  <Col className='p-4 px-5'>
                    <h5 className='text-success text-center'>
                      WHAT OUR CUSTOMER SAYI
                    </h5>
                    <div className={CLASSES.content_center}>
                      <Rating count={review.rating_stars} />
                    </div>
                    <p className='text-center customer-comment' style={{ fontSize: 18 }}>
                      {review.comment}
                    </p>
                  </Col>
                </Row>
              </Container>
            </div>
            <Image
              style={{
                position: 'absolute',
                top: '20%',
                right: '5%',
                zIndex: 4,
              }}
              width={'10%'}
              src={BannerImage}
            />
          </Carousel.Item>
        ))}
      </Carousel>
      <div
        style={{
          position: 'absolute',
          bottom: '25%',
          left: 0,
          right: 0,
          zIndex: 1,
        }}
        className={CLASSES.content_center + 'gap-4 mt-5'}
      >
        {data.map((review, i) => (
          <div key={review.id}>
            <h5 style={{ color: index == i ? '#1c4a33' : '#87938d', fontSize: '85%' }}>
              {review.customer_name}
            </h5>
            <div className='text-success text-bold' style={{ height: 20, fontSize: '85%' }}>
              {index == i ? moment(review.date).format('MMMM YYYY') : ''}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SliderBanner
