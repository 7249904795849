import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import ProductCard from '../../../components/common/ProductCard'
import { CLASSES } from '../../../assets/styles/classes'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import ProductsService from '../../../services/ProductsService'
import LoadingContainer from '../../../components/common/LoadingContainer'

function OurProducts({ data = [] }) {
  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(true)
  const [products, setProducts] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [hasMore, setHasMore] = React.useState(true)

  React.useEffect(() => {
    getProducts()
  }, [])

  const getProducts = async (_page = page) => {
    setLoading(true)
    try {
      const response = await ProductsService.getProducts(_page)
      if (_page == 1) {
        setProducts(response.products.data)
      } else {
        setProducts((prevPosts) => [...prevPosts, ...response.products.data])
      }
      setHasMore(response.products.next_page_url !== null)
      console.log(response)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const loadMore = () => {
    if (hasMore) {
      setPage((prevPage) => prevPage + 1)
      getProducts(page + 1)
    }
  }

  return (
    <Container className='py-4 d-flex flex-column flex-grow-1'>
      <Row className='my-5 justify-content-center'>
        <div style={{ fontSize: 30 }} className='text-success text-center mt-2'>
          ALL PRODUCTS
        </div>

        {products.map((product, index) => (
          <Col key={product.id} md={4} sm={6} className='my-4'>
            <ProductCard item={product} />
          </Col>
        ))}
      </Row>
      <div
        className={
          CLASSES.content_center + 'position-relative flex-grow-1 pb-5'
        }
      >
        <LoadingContainer loading={loading}>
          {hasMore && !loading && (
            <Button
              variant='success'
              style={{ borderRadius: 0 }}
              onClick={loadMore}
            >
              View More +
            </Button>
          )}
        </LoadingContainer>
      </div>
    </Container>
  )
}

export default OurProducts
