import React, { useState } from 'react'
import { Rating as MyRating } from 'react-simple-star-rating'

export function Rating({count = 0}) {
  const [rating, setRating] = useState(0)

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate)

    // other logic
  }
  // Optinal callback functions
  const onPointerEnter = () => console.log('Enter')
  const onPointerLeave = () => console.log('Leave')
  const onPointerMove = (value, index) => console.log(value, index)

  return (
    <div className='App my-4'>
      <MyRating
        initialValue={count}
        size={23}
        // allowHover={false}
        allowFraction={false}
        readonly
        onClick={handleRating}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
        onPointerMove={onPointerMove}
        /* Available Props */
      />
    </div>
  )
}