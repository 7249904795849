import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import LocationIcon from '../assets/icons/common/location.svg'
import PhoneIcon from '../assets/icons/common/phone-black.svg'
import MessageIcon from '../assets/icons/common/message.svg'

function ContactUs() {
  return (
    <Container className='py-5'>
      <Row className='mt-3 position-relative'>
        <Col
          xs={12}
          className='d-flex flex-column justify-content-center align-items-center'
        >
          <h2 className='mb-5'>Contact us</h2>
          <Image src={require('../assets/icons/common/logo.png')} />
        </Col>
      </Row>
      <Row className='my-5'>
        <Col md={5}>
          <div className='d-flex align-items-center'>
            <Image src={LocationIcon} />
            <div className='ms-2 text-bold'>Address</div>
          </div>
          <hr />
          <p>
            251 Arma Majeda Malik Tower,6th Floor(lift-5),Merul
            Badda,Gulshan,Dhaka-1212
          </p>
        </Col>

        <Col md={4}>
          <div className='d-flex align-items-center'>
            <Image src={PhoneIcon} />
            <div className='ms-2 text-bold'>Phone Number</div>
          </div>
          <hr />
          <p>+8801731358993</p>
        </Col>

        <Col md={3}>
          <div className='d-flex align-items-center'>
            <Image src={MessageIcon} />
            <div className='ms-2 text-bold'>Email Address</div>
          </div>
          <hr />
          <p>info@myagc24.com</p>
        </Col>
      </Row>
    </Container>
  )
}

export default ContactUs
