import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { IMAGE_URL } from '../../utils/constants'
import { CLASSES } from '../../assets/styles/classes'

const ProductZoomView = ({ images, thumbnail }) => {
  const [item, setItem] = React.useState(0)
  const [state, setState] = React.useState({
    show_modal: false,
    modal_images: images,
  })
  // const [] = React.useState(false);

  const _images = images && JSON.parse(images) || thumbnail && [thumbnail] || []

  const show_image_in_modal = (image) => {
    setState({
      modal_images: image,
      show_modal: true,
    })
  }

  const close_modal = () => {
    setState((pv) => ({ ...pv, show_modal: false }))
  }

  const Controls = ({ zoomIn, zoomOut, resetTransform }) => (
    <div className='d-flex align-items-center justify-content-center zoom-controller'>
      <button className='zoom-button' onClick={() => zoomIn()}>
        +
      </button>
      <button className='zoom-button' onClick={() => zoomOut()}>
        -
      </button>
      {/* <button onClick={() => resetTransform()}>x</button> */}
    </div>
  )

  return (
    <div className='w-100'>
      <div className='w-100 bg-white p-3 overflow-hidden d-flex justify-content-center align-items-center'>
        <img
          height={'367px'}
          src={_images[item] ? IMAGE_URL+ _images[item] : 'https://via.placeholder.com/500'}
          alt='...'
          className='productinfo-image-preview pointer'
          onClick={() => show_image_in_modal(IMAGE_URL+ _images[item])}
          // onMouseEnter={() => show_image_in_modal(state.modal_images)}
          // onMouseLeave={() => show_image_in_modal(state.modal_images)}
        />
      </div>
      <div
        style={{ height: 92 }}
        className='d-flex justify-content-center mt-2'
      >
        {_images.map((_item, i) => (
          <div
            onClick={() => setItem(i)}
            key={i}
            style={{
              height: '100%',
              width: '25%',
              border: item === i ? '1px solid #13A050' : '',
            }}
            className='bg-white pointer overflow-hidden d-flex justify-content-center p-1 mx-1'
          >
            <img
              height={'100%'}
              src={IMAGE_URL + _item}
              alt='...'
              className='productinfo-image-preview'
            />
          </div>
        ))}
      </div>
      <Modal
        show={state.show_modal}
        onHide={close_modal}
        size='xl'
        // fullscreen
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id='contained-modal-title-vcenter'>
            <b>{state.product_name}</b>
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body className={CLASSES.content_center}>
          <TransformWrapper centerOnInit>
            {(utils) => (
              <React.Fragment>
                <TransformComponent
                  wrapperStyle={{  width: '100%' }}
                  wrapperClass='w-100'
                >
                  <img
                    width={'100%'}
                    src={state.modal_images}
                    alt='product image'
                    className='product_modal_images'
                  />
                </TransformComponent>
                {/* <Controls {...utils} /> */}
              </React.Fragment>
            )}
          </TransformWrapper>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ProductZoomView
