import { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Carousel from 'react-bootstrap/Carousel'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import MainBanner from '../../../assets/images/home/banner.jpg'
import ControlLeftIcon from '../../../assets/icons/common/control-left-2.svg'
import ControlRightIcon from '../../../assets/icons/common/control-right-2.svg'
import { CLASSES } from '../../../assets/styles/classes'
import { IMAGE_URL } from '../../../utils/constants'
import { useNavigate } from 'react-router-dom'

function SliderBanner({ data = [] }) {
  const navigate = useNavigate()
  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex)
  }

  return (
    <Carousel
      // activeIndex={index}
      hidden={false}
      indicators
      onSlide={handleSelect}
      onSelect={handleSelect}
      prevIcon={
        <Image
          style={{
            width: 70,
            height: 70,
            backgroundColor: 'green',
            borderRadius: '50%',
          }}
          src={ControlLeftIcon}
        />
      }
      nextIcon={
        <Image
          style={{
            width: 70,
            height: 70,
            backgroundColor: 'green',
            borderRadius: '50%',
          }}
          src={ControlRightIcon}
        />
      }
    >
      {data.map((banner, i) => (
        <Carousel.Item key={banner.id} className='position-relative'>
          <Image
            style={{ height: '100vh', width: '100%', objectFit: 'cover' }}
            src={
              (banner.image_path?.startsWith('http') && banner.image_path) ||
              (banner.image_path && IMAGE_URL + banner.image_path) ||
              MainBanner
            }
          />
          <div
            className={CLASSES.content_center}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              zIndex: 1,
            }}
          >
            <Container>
              <Row
                style={{
                  backgroundColor: '#3737374D',
                  // backgroundImage:
                  //   'linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0))',
                  // borderRadius: 10,
                  maxWidth: 1000,
                }}
              >
                <Col md={10} className='p-4 px-5'>
                  <h1
                    style={{
                      color: 'white',
                      fontFamily: 'KaushanScript-Regular',
                    }}
                  >
                    {banner.title}
                  </h1>
                  <p
                    style={{
                      color: 'rgba(73, 73, 73, 1)',
                      fontSize: 18,
                      color: 'white',
                    }}
                  >
                    {banner.description}
                  </p>
                  <Button
                    onClick={() => navigate('/about')}
                    style={{ maxWidth: 174 }}
                    variant='success'
                  >
                    About us
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export default SliderBanner
