import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import ProductCard from '../../../components/common/ProductCard'
import { CLASSES } from '../../../assets/styles/classes'
import { useNavigate } from 'react-router-dom'

function OurProducts({ data = [] }) {
  const navigate = useNavigate()
  return (
    <Container className='py-4 mt-n5'>
      <Row className='my-5 justify-content-center'>
        <div style={{ fontSize: 30 }} className='text-success text-center'>
          OUR PRODUCTS
        </div>
        <div className={CLASSES.content_between}>
        <div style={{width: '20%'}}></div>
        <hr className='mt-3' style={{ border: '2px solid #c7a056', width: '60%' }} />
        <div onClick={() => navigate('/products')} style={{width: '20%', textAlign: 'right'}} className='text-bold pointer'>See All</div>
        </div>
        <p className='text-center text-secondary text-bold'>
          Enjoy the benefits of Ayurveda Panchakarma Therapies. Choose from our
          products bellow
        </p>

        {data.map((product, index) => (
          <Col key={product.id} md={4} sm={6} className='my-4'>
            <ProductCard item={product} name={'ACTUAL OMEGA-3.6.9.(SOFTGEL)'} />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default OurProducts
