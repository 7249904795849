import React from 'react'
import {
  Row,
  Col,
  Container,
  Form,
  Image,
  Button,
} from 'react-bootstrap'
import moment from 'moment'
import { CLASSES } from '../../../assets/styles/classes'
import PlusIcon from '../../../assets/icons/common/plus-white.svg'
import DropDownIcon from '../../../assets/icons/common/arrow-down-bold.svg'

import Product1 from '../../../assets/images/products/p-1.png'
import Product2 from '../../../assets/images/products/p-2.png'
import Product3 from '../../../assets/images/products/p-3.png'
import Product4 from '../../../assets/images/products/p-4.png'
import Product5 from '../../../assets/images/products/p-5.png'
import Product6 from '../../../assets/images/products/p-6.png'
import Product7 from '../../../assets/images/products/p-7.png'
import Product8 from '../../../assets/images/products/p-8.png'


// import { USERS_2 } from '../../../utils/faker'
import DropDownX from '../../../components/common/DropDownX'
import { useNavigate } from 'react-router-dom'
import HorizontalNavbar from '../components/HorizontalNavbar'

import ProductCard from '../../../components/common/ProductCard';


export default function ProductsByCategory() {
  const navigate = useNavigate()

  return (
    <Container className='p-4 py-2 mt-2'>
      <div>
        Home / Category / <span className='color-theme'>capsule Items</span>
        <div className={CLASSES.content_between + 'my-2'}>
          <HorizontalNavbar />
        
          <div className={CLASSES.content_between + 'px-0 px-md-2'}>
            <DropDownX
              isBoldIcon
              label='Sort by : ' 
              items={[
              'Popularity',
              'Latest',
              'Price high to low',
              'Price low to high ',
            ]}/>
          </div>
        </div>
      </div>
      <Row className="mb-5">
          <Col md={3} sm={4} xs={6} className="my-1 my-md-2 my-lg-3 p-1 p-md-2 p-lg-3">
            <ProductCard src={Product1} name={'ACTUAL OMEGA-3.6.9.(SOFTGEL)'} />
          </Col>
          <Col md={3} sm={4} xs={6} className="my-1 my-md-2 my-lg-3 p-1 p-md-2 p-lg-3">
            <ProductCard src={Product2} name={'ACTUAL OMEGA-3.6.9.(SOFTGEL)'} />
          </Col>
          <Col md={3} sm={4} xs={6} className="my-1 my-md-2 my-lg-3 p-1 p-md-2 p-lg-3">
            <ProductCard src={Product3} name={'ACTUAL OMEGA-3.6.9.(SOFTGEL)'} />
          </Col>
          <Col md={3} sm={4} xs={6} className="my-1 my-md-2 my-lg-3 p-1 p-md-2 p-lg-3">
            <ProductCard src={Product4} name={'ACTUAL OMEGA-3.6.9.(SOFTGEL)'} />
          </Col>
          <Col md={3} sm={4} xs={6} className="my-1 my-md-2 my-lg-3 p-1 p-md-2 p-lg-3">
            <ProductCard src={Product5} name={'ACTUAL OMEGA-3.6.9.(SOFTGEL)'} />
          </Col>
          <Col md={3} sm={4} xs={6} className="my-1 my-md-2 my-lg-3 p-1 p-md-2 p-lg-3">
            <ProductCard src={Product6} name={'ACTUAL OMEGA-3.6.9.(SOFTGEL)'} />
          </Col>
          <Col md={3} sm={4} xs={6} className="my-1 my-md-2 my-lg-3 p-1 p-md-2 p-lg-3">
            <ProductCard src={Product7} name={'ACTUAL OMEGA-3.6.9.(SOFTGEL)'} />
          </Col>
          <Col md={3} sm={4} xs={6} className="my-1 my-md-2 my-lg-3 p-1 p-md-2 p-lg-3">
            <ProductCard src={Product8} name={'ACTUAL OMEGA-3.6.9.(SOFTGEL)'} />
          </Col>
        </Row>

        <Row className='justify-content-center align-items-center my-5'>
          <Button className='view-more-btn'>View More</Button>
        </Row>
    </Container>
  )
}
