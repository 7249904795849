import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Image from 'react-bootstrap/Image'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Logo from '../../assets/icons/common/logo.png'
import { Button } from 'react-bootstrap'

function NavbarOffcanvas() {
  const location = useLocation()
  const navigate = useNavigate()
  const onClick = () => {
    // navigate('/contact')
    // window.scrollTo({ bottom: 0, behavior: 'smooth' })
    document.querySelector('footer').scrollIntoView({ behavior: 'smooth', block: 'end' })
  }
  const className = (path = '/products') =>
    ` px-2 ${
      (path !== '/' && location.pathname.startsWith(path)) ||
      location.pathname === path
        ? 'text-black text-bold'
        : 'text-white'
    }`
  return (
    <Navbar.Offcanvas placement='start' id='responsive-navbar-nav'>
      <div>
        <Offcanvas.Header closeButton>
          <div className='w-100'>
            <Link to={'/'}>
              {/* <Image style={{ width: '100%' }} src={Logo} /> */}
              <h3 className='text-white text-center ms-4'>Logo</h3>
            </Link>
            <hr className='mb-0' />
          </div>
        </Offcanvas.Header>
        <Nav className='gap-3 align-items-center'>
          <Link className={className('/')} to={'/'}>
            Home
          </Link>
          <Link className={className('/products')} to={'/products'}>
            Products
          </Link>
          <Link className={className('/documents')} to={'/documents'}>
            Documents
          </Link>
          <Link className={className('/about')} to={'/about'}>
            About Us
          </Link>
          <Button
            onClick={onClick}
            variant='success'
            className='navbar-link px-2 text-white'
          >
            Contact Us
          </Button>
        </Nav>
      </div>
    </Navbar.Offcanvas>
  )
}

export default NavbarOffcanvas
