import ProductView from '../pages/products/pages/ProductView';
import Products from '../pages/products/pages/Products';
import ProductsByCategory from '../pages/products/pages/ProductsByCategory';


const ProductsRoutes = [
  {
    path: '/products',
    component: Products,
  },
  {
    path: '/products/view/:id',
    component: ProductView,
  },
  {
    path: '/products/category',
    component: ProductsByCategory,
  },
];

export default ProductsRoutes
