import Container from 'react-bootstrap/Container'

export default function Privacy() {
  return (
    <Container className='py-5'>
      <h1 className='mb-4'>Privacy Policy</h1>
      <div
        className='user-setting-panel page-margin termsofuse pt_shadow'
        style={{ marginBottom: 50 }}
      >
        <h4>
          <strong>
            <span style={{ fontSize: 18 }}>Last Updated: March 3, 2023</span>
          </strong>
        </h4>
        <h2>
          <strong>
            <span style={{ fontSize: 16 }}>
              This Privacy Policy applies when you use our UploadTheTruth
              Platform. It is important that you read our full Privacy Policy
              for the full explanation of our data practices, including about
              how your information is shared and how you can control and access
              your information.
            </span>
          </strong>
        </h2>
        <h2>What information do we collect?</h2>
        <p>
          <span style={{ fontSize: 16 }}>
            We collect the following types of information.
          </span>
        </p>
        <ul className='ml-2 mt-2'>
          <li className=''>
            Your username, password and e-mail address when you register for an
            Kurkuri account.
            <br />
            <br />
          </li>
          <li className='mt-2'>
            Profile information that you provide for your user profile (e.g.,
            full name, picture, phone number). This information allows us to
            help you or others be "found" on Kurkuri.
            <br />
            <br />
          </li>
          <li className='mt-2'>
            User Content (e.g., photos, comments, and other materials) that you
            post to the Service.
            <br />
            <br />
          </li>
          <li className='mt-2'>
            Communications between you and Kurkuri. For example, we may send you
            Service-related emails (e.g., account verification, changes/updates
            to features of the Service, technical and security notices). Note
            that you may not opt out of Service-related e-mails.
          </li>
        </ul>
        <p>
          <strong>Cookies and similar technologies:</strong>
        </p>
        <ul className='ml-2 mt-2'>
          <li className=''>
            In future, When you visit the Service- we may use cookies and
            similar technologies like pixels, and local storage to collect
            information about how you use Kurkuri and provide features to you
          </li>
        </ul>
        <h2>How do we use your information?</h2>
        <p className=''>
          In addition to some of the specific uses of information we describe in
          this Privacy Policy, we may use information that we receive to:
        </p>
        <ul className='ml-2 mt-2'>
          <li className=''>
            help you efficiently access your information after you sign in
            <br />
            <br />
          </li>
          <li className='mt-2'>
            provide personalized content and information to you and others,
            which could include online ads or other forms of marketing
            <br />
            <br />
          </li>
          <li className='mt-2'>
            provide, improve, test, and monitor the effectiveness of our Service
            <br />
            <br />
          </li>
          <li className='mt-2'>
            develop and test new products and features
            <br />
            <br />
          </li>
          <li className='mt-2'>
            monitor metrics such as total number of visitors, traffic, and
            demographic patterns
            <br />
            <br />
          </li>
          <li className='mt-2'>diagnose or fix technology problems</li>
        </ul>
        <div>
          <div>
            <span style={{ fontSize: 18 }}>
              <strong>
                Your Choices About Your Information
                <br />
              </strong>
            </span>
          </div>
          <div>
            <strong>
              Your account information and profile (manage or delete):
            </strong>
          </div>
          <br />
          <div>
                Update your account at any time by logging in and changing your
            profile data.
          </div>
          <br />
          <div>
            <strong>How long we keep your User Content:</strong>
          </div>
          <br />
          <ul>
            <li>
              We provide you with the ability to access and delete your data. We
              store data until it is no longer necessary to provide our services
              or until your account is deleted – whichever comes first. When you
              delete your account, we delete things that you have published,
              such as your photos, videos and status updates.
            </li>
          </ul>
          <br />
          <div>
            <span style={{ fontSize: 16 }}>
              <strong>Children's Privacy</strong>
            </span>
          </div>
          <br />
          <div>
            <span style={{ fontSize: 16 }}>
              UploadTheTruth does not knowingly collect or solicit any
              information from anyone under the age of 13 or knowingly allow
              such persons to register for the Service. The Service and its
              content are not directed at children under the age of 13. In the
              event that we learn that we have collected personal information
              from a child under age 13 without parental consent, we will delete
              that information as quickly as possible. If you believe that we
              might have any information from or about a child under 13, please
              contact us.
            </span>
            <br />
            <br />
          </div>
          <div>
            <span style={{ fontSize: 18 }}>
              <strong>Other Web Sites And Services</strong>
            </span>
          </div>
          <br />
          <div>
            <span style={{ fontSize: 16 }}>
              We are not responsible for the practices employed by any websites
              or services linked to or from our Service, including the
              information or content contained within them. Please remember that
              when you use a link to go from our Service to another website or
              service, our Privacy policy does not apply to those third-party
              websites or services. Your browsing and interaction on any
              third-party website or service, including those that have a link
              on our website, are subject to that third party's own rules and
              policies. In addition, you agree that we are not responsible and
              do not have control over any third-parties that you authorize to
              access your User Content. If you are using a third-party website
              or service and you allow them to access your User Content you do
              so at your own risk.
            </span>
          </div>
          <div>
            <span style={{ fontSize: 16 }}>
              How To Contact Us About A Deceased User
            </span>
          </div>
          <br />
          <div>
            <span style={{ fontSize: 16 }}>
              In the event of the death of an UploadTheTruth User, please
              contact us. We will usually conduct our communication via email.
              If we require any other information, we will contact you at the
              email address you have provided in your request.
            </span>
          </div>
          <div> </div>
          <div>
            <strong>
              <span style={{ fontSize: 18 }}>
                Changes To Our Privacy policy
              </span>
            </strong>
          </div>
          <br />
          <div>
            <span style={{ fontSize: 16 }}>
              UploadTheTruth may modify or update this Privacy policy from time
              to time, so please review it periodically. We will provide you
              additional forms of notice of modifications or updates as
              appropriate under the circumstances. we will post these changes
              here and revise the "Last Updated" date at the bottom of the
              Privacy policy. Your continued use of UploadTheTruth or the
              Service after any modification to this Privacy policy will
              constitute your acceptance of such modification.
            </span>
          </div>
        </div>
      </div>
    </Container>
  )
}
