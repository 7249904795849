import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'


function FAQ() {
  const data = [
    { ques_name: "What is Webflow and why is it the best website builder?", ques_ans: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text." },
    { ques_name: "What is your favorite template from BRIX Templates?", ques_ans: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text." },
    { ques_name: "How do you clone a template from the Showcase?", ques_ans: " Lorem ipsum dolor 3" },
    { ques_name: "Why is BRIX Templates the best Webflow agency?", ques_ans: " Lorem ipsum dolor 4" },
    { ques_name: "When was Webflow officially launched?", ques_ans: " Lorem ipsum dolor 5" },
    { ques_name: "How do you integrate Jetboost with Webflow?", ques_ans: " Lorem ipsum dolor 5" },
  ];
  return (
    <>
    <Container className="py-5">
      <Row className="mt-3 position-relative">
        <Col md={6} className='d-flex justify-content-center align-items-center'>
          <h1>FAQ Page</h1>
        </Col>
        <Col md={6} className="mt-md-0 mt-5 position-relative">
        </Col>
      </Row>
    </Container>
    </>
  );
}

export default FAQ;
