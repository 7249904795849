import React from 'react'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import { Navigate, useNavigate } from 'react-router-dom'
import { CLASSES } from '../../assets/styles/classes'
import { IMAGE_URL } from '../../utils/constants'

function ProductCard({ item }) {
  const navigate = useNavigate()
  const [active, setActive] = React.useState(false)
  const imageBackground = 'white'
  return (
    <div className={'position-relative'}>
      <div className={CLASSES.content_center}>
        <div
          onClick={() => navigate(`/products/view/${item.id}`, { state: item })}
          style={{
            width: 258,
            aspectRatio: 1 / 1,
            position: 'relative',
            backgroundColor: 'white',
            // borderRadius: 10,
            overflow: 'hidden',
          }}
          className={CLASSES.content_center + 'pointer'}
        >
          <Image
            style={{ backgroundColor: imageBackground, height: '100%'}}
            src={
              item?.thumbnail
                ? IMAGE_URL + item?.thumbnail
                : 'https://via.placeholder.com/300'
            }
          />
        </div>
      </div>

      <div className='p-3'>
        <p
          onClick={() => navigate(`/products/view/${item.id}`, { state: item })}
          style={{ fontWeight: 600 }}
          className='m-0 text-center text-success mb-3 pointer'
        >
          {item.name}
        </p>
        {/* <p className='mt-1 text-center text-secondary product-description'>
            {item.description}
          </p> */}
        <p
          dangerouslySetInnerHTML={{ __html: item.description }}
          className='mt-1 text-center dangerouslySetInnerHTML text-secondary product-description'
        />
      </div>
      <div className={CLASSES.content_center}>
        <Button
          variant='outline-success'
          style={{ borderRadius: 0 }}
          onClick={() => navigate(`/products/view/${item.id}`, { state: item })}
        >
          Learn More +
        </Button>
      </div>
    </div>
  )
}

export default ProductCard
