import React from 'react'
import Container from 'react-bootstrap/Container'
import SliderBanner from '../components/SliderBanner'
import { Puff, ThreeDots } from 'react-loader-spinner'
//
import AboutUS from '../components/AboutUS'
import WhyChooseUs from '../components/WhyChooseUs'
import OurProducts from '../components/OurProducts'
import LegalDocuments from '../components/LegalDocuments'
import BoardMembers from '../components/BoardMembers'
import Subscription from '../components/Subscription'
import AdBanner from '../components/AdBanner'
import SmallSlider from '../components/SmallSlider'
import HomePageService from '../../../services/HomePageService'
import LoadingContainer from '../../../components/common/LoadingContainer'

function Home() {
  const [loading, setLoading] = React.useState(true)
  const [homePageData, setHomePageData] = React.useState({})
  const { products, board_members, documents, reviews, banners = [{image_path: null}] } = homePageData

  React.useEffect(() => {
    getHomePageData()
  }, [])
  const getHomePageData = async () => {
    setLoading(true)
    try {
      const response = await HomePageService.getHomePageData()
      setHomePageData(response)
      // console.log(response)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <LoadingContainer loading={loading}>
      <Container fluid className='p-0'>
        <SliderBanner data={banners} />
        <AboutUS />
        <WhyChooseUs />
        <OurProducts data={products} />
        <LegalDocuments data={documents} />
        <BoardMembers data={board_members} />
        <AdBanner />
        <SmallSlider data={reviews} />
        <Subscription />
      </Container>

      {/* {loading && (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            zIndex: 10000,
          }}
          className='d-flex justify-content-center align-items-center flex-column bg-body'
        >
          <ThreeDots
            visible={true}
            height='80'
            width='80'
            color='#4fa94d'
            radius='9'
            ariaLabel='three-dots-loading'
            wrapperStyle={{}}
            wrapperClass=''
          />
        </div>
      )} */}
    </LoadingContainer>
  )
}

export default Home
