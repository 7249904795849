import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'
import { Form, FormControl, FormGroup, InputGroup } from 'react-bootstrap'
import Swal from 'sweetalert2'
//
import Tree from '../../../assets/images/home/tree.png'
import LeftImage from '../../../assets/images/home/subscription-left.png'
import RightImage from '../../../assets/images/home/subscription-right.png'
import { CLASSES } from '../../../assets/styles/classes'
import HomePageService from '../../../services/HomePageService'
import ButtonX from '../../../components/common/ButtonX'

function Subscription() {
  const [email, setEmail] = useState('')
  const [isLoading, setLoading] = useState(false)

  const backgroundImageStyle = {
    backgroundImage: `url(${Tree})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    // height: '400px', // Set the height as needed
  }

  const handleSubscription = async () => {
    try {
      setLoading(true)
      const response = await HomePageService.subscription(email)
      // const response = {success: true}
      if (response.success) {
        Swal.fire({
          title: response.message,
          icon: 'success',
          showConfirmButton: false,
          timer: 2000,
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log('I was closed by the timer')
          }
        })
      }
    } catch (error) {
      console.log(error)
    } finally {
      setEmail('')
      setLoading(false)
    }
  }

  return (
    <Container
      fluid
      className={CLASSES.content_between + 'py-2 overflow-hidden'}
      style={{ backgroundColor: '#f5fff9', ...backgroundImageStyle }}
    >
      <div style={{ marginLeft: -60 }}>
        <Image width={'100%'} src={LeftImage} />
      </div>
      <Container>
        <Row className='my-5 justify-content-center'>
          <Col md={7} className='text-center'>
            <h1 style={{ fontWeight: 600, color: '#1c4a33' }}>
              Lest's Stay Connected
            </h1>
            <p className='my-3' style={{ color: '#879393' }}>
              Sign up for <span className='text-success'>10% off</span> and be
              the first to know about new products,
              <br /> offers and all the tea.
            </p>
            {/* <Form
              onSubmit={(e) => {
                e.preventDefault()
                handleSubscription()
              }}
            >
              <InputGroup className='flex-nowrap'>
                <FormControl
                  className='border-0 px-4 text-bold'
                  placeholder='Enter your email'
                  style={{ minWidth: 200 }}
                  type='email'
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <InputGroup.Text className='text-white border-0 text-bold p-0'>
                  <ButtonX
                    type='submit'
                    label={isLoading ? 'SUBSCRIBING...' : 'SUBSCRIBE'}
                    isLoading={isLoading}
                    style={{
                      backgroundColor: '#8dbd54',
                      fontSize: 14,
                      border: 0,
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                  />
                </InputGroup.Text>
              </InputGroup>
            </Form> */}
          </Col>
        </Row>
      </Container>
      <div style={{ marginRight: -12 }}>
        <Image width={'100%'} src={RightImage} />
      </div>
    </Container>
  )
}

export default Subscription
