import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'

import { CLASSES } from '../../../assets/styles/classes'
import { IMAGE_URL } from '../../../utils/constants'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import LoadingContainer from '../../../components/common/LoadingContainer'
import DocumentsService from '../../../services/DocumentsService'
import ZoomView from '../../../components/common/ZoomView'

function Documents() {
  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(true)
  const [documents, setDocuments] = React.useState([])
  const [state, setState] = React.useState({
    show_modal: false,
    title: null,
    modal_images: null,
  })

  React.useEffect(() => {
    getDocuments()
  }, [])
  const getDocuments = async () => {
    setLoading(true)
    try {
      const response = await DocumentsService.getDocuments()
      setDocuments(response.documents)
      console.log(response)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleState = (document) =>
    setState((pv) => ({
      ...pv,
      modal_images: document.file_path,
      title: document.title,
      show_modal: !pv.show_modal,
    }))

  return (
    <LoadingContainer loading={loading}>
      <Container className='py-4'>
        <Row className={CLASSES.content_center + ' my-5'}>
          <div
            style={{ fontSize: 30 }}
            className='text-success text-center mt-2'
          >
            ALL LEGAL DOCUMENTS
          </div>
          {documents.map((document, i) => (
            <Col key={document.id} lg={3} md={4} sm={6} className='my-4'>
              <div className={CLASSES.content_center}>
                <div
                  style={{
                    width: 307,
                    aspectRatio: 1 / 1,
                    overflow: 'hidden',
                  }}
                  className={CLASSES.content_center}
                >
                  <Image
                    height={'100%'}
                    src={
                      (document.file_path &&
                      document.file_path.startsWith('http')
                        ? document.file_path
                        : IMAGE_URL + document.file_path) ||
                      'https://sertifier.com/blog/wp-content/uploads/2020/10/certificate-text-samples.jpg'
                    }
                    className='pointer'
                    // onClick={() => setActive((x) => !x)}
                    onClick={() => handleState(document)}
                  />
                </div>
              </div>
              <div
                onClick={() => handleState(document)}
                className='text-center text-bold pointer my-4'
              >
                {document.title}
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <ZoomView state={state} handleState={() => handleState({})} />
    </LoadingContainer>
  )
}

export default Documents
