import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { CLASSES } from '../../../assets/styles/classes'
import { Image } from 'react-bootstrap'
import { IMAGE_URL } from '../../../utils/constants'

function LegalDocuments({ data = [] }) {
  return (
    <Container id='documents' className='py-4'>
      <Row className={CLASSES.content_center + ' my-5'}>
        <h5 className='text-center'>Legal Documents</h5>
        {data.map((document, i) => (
          <Col
            key={document.id}
            lg={3}
            md={4}
            sm={6}
            className='my-4'
          >
            <div className={CLASSES.content_center}>
              <div
                style={{
                  width: 307,
                  aspectRatio: 1 / 1,
                  overflow: 'hidden',
                }}
                className={CLASSES.content_center}
              >
                <Image
                  height={'100%'}
                  src={
                    (document.file_path.startsWith('http')
                      ? document.file_path
                      : IMAGE_URL + document.file_path) ||
                    'https://sertifier.com/blog/wp-content/uploads/2020/10/certificate-text-samples.jpg'
                  }
                  className='pointer'
                  // onClick={() => setActive((x) => !x)}
                />
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default LegalDocuments
