import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'

import Tree from '../../../assets/images/home/tree.png'
import Add from '../../../assets/images/home/add.svg'

import F1 from '../../../assets/icons/home/f-1.svg'
import F2 from '../../../assets/icons/home/f-2.svg'
import F3 from '../../../assets/icons/home/f-3.svg'
import F4 from '../../../assets/icons/home/f-4.svg'

function WhyChooseUs() {
  const backgroundImageStyle = {
    backgroundImage: `url(${Tree})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    // height: '400px', // Set the height as needed
  }

  return (
    <Container
      fluid
      className='py-2 overflow-hidden'
      style={{ backgroundColor: '#f5fff9', ...backgroundImageStyle }}
    >
      {/* <div style={{zIndex:1}} className="position-absolute">
            <Image src={Tree}/>
          </div> */}
      <Container>
        <Row className='my-5 justify-content-center'>
          <Col md={6} className=''>
            <h4 style={{ fontWeight: 600 }}>
              Why <span className='text-success'>Choose Us</span>
            </h4>
            <p>
              In publishing and graphic design, Lorem ipsum is a placeholder
              text commonly used to demonstrate the visual form of a document or
              a typeface without relying on meaningful content. In publishing
              and graphic design, Lorem ipsum is a placeholder.
            </p>

            {[
              { title: '100% Pure', icon: F1 },
              { title: 'Medical Sacisficed', icon: F2 },
              { title: 'Professional Staff', icon: F3 },
              { title: 'Affordable Price', icon: F4 },
            ].map((item, i) => (
              <div key={i} className='d-flex align-items-start'>
                <Image style={{}} src={item.icon} />
                <div className='ms-3'>
                  <h6 style={{ fontWeight: 600 }}>{item.title}</h6>
                  <p>
                    In publishing and graphic design, Lorem ipsum is a
                    placeholder text commonly used to demonstrate the visual
                    form of a document
                  </p>
                </div>
              </div>
            ))}
          </Col>
          <Col md={6} className='d-flex justify-content-center'>
            <Image style={{ width: '100%' }} src={Add} />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default WhyChooseUs
