import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'

function About() {
  return (
    <>
      <Container className='py-4'>
        <div
          style={{ fontSize: 30 }}
          className='text-success text-center my-5 pt-2'
        >
          ABOUT US
        </div>
        <Row className='position-relative '>
          <Col md={3} className='mb-4'>
            {/* <Image width={'100%'} src={'https://via.placeholder.com/300'} /> */}
            <Image width={'100%'} src={'https://st4.depositphotos.com/13193658/30158/i/450/depositphotos_301587484-stock-photo-handsome-businessman-formal-wear-glasses.jpg'} />
          </Col>
          <Col md={9} className='mb-4'>
            <h5 className='color-theme text-bold mb-4'>CEO's message</h5>
            <p>
              In publishing and graphic design, Lorem ipsum is a placeholder
              text commonly used to demonstrate the visual form of a document or
              a typeface without relying on meaningful content. Lorem ipsum may
              be used as a placeholder before the final copy is available In
              publishing and graphic design, Lorem ipsum is a placeholder text
              commonly used to demonstrate the visual form of a document or a
              typeface without relying on meaningful content. Lorem ipsum may be
              used as a placeholder before the final copy is availableIn
              publishing and graphic design, Lorem ipsum is a placeholder text
              commonly used to demonstrate the visual form of a document or a
              typeface without relying on meaningful content. Lorem ipsum may be
              used as a placeholder before the final copy is available
            </p>
          </Col>
          <Col xs={12}>
            <div className='my-5'>
              <h5 className='color-theme text-bold mb-4'>About Our Company</h5>
              <p>
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                or a typeface without relying on meaningful content. Lorem ipsum
                may be used as a placeholder before the final copy is available
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                or a typeface without relying on meaningful content. Lorem ipsum
                may be used as a placeholder before the final copy is
                availableIn publishing and graphic design, Lorem ipsum is a
                placeholder text commonly used to demonstrate the visual form of
                a document or a typeface without relying on meaningful content.
                Lorem ipsum may be used as a placeholder before the final copy
                is available In publishing and graphic design, Lorem ipsum is a
                placeholder text commonly used to demonstrate the visual form of
                a document or a typeface without relying on meaningful content.
                Lorem ipsum may be used as a placeholder before the final copy
                is available In publishing and graphic design, Lorem ipsum is a
                placeholder text commonly used to demonstrate the visual form of
                a document or a typeface without relying on meaningful content.
                Lorem ipsum may be used as a placeholder before the final copy
                is availableIn publishing and graphic design, Lorem ipsum is a
                placeholder text commonly used to demonstrate the visual form of
                a document or a typeface without relying on meaningful content.
                Lorem ipsum may be used as a placeholder before the final copy
                is availableIn publishing and graphic design, Lorem ipsum is a
                placeholder text commonly used to demonstrate the visual form of
                a document or a typeface without relying on meaningful content.
                Lorem ipsum may be used as a placeholder before the final copy
                is available In publishing and graphic design, Lorem ipsum is a
                placeholder text commonly used to demonstrate the visual form of
                a document or a typeface without relying on meaningful content.
                Lorem ipsum may be used as a placeholder before the final copy
                is availableIn publishing and graphic design, Lorem ipsum is a
                placeholder text commonly used to demonstrate the visual form of
                a document or a typeface without relying on meaningful content.
                Lorem ipsum may be used as a placeholder before the final copy
                is availableIn publishing and graphic design, Lorem ipsum is a
                placeholder text commonly used to demonstrate the visual form of
                a document or a typeface without relying on meaningful content.
                Lorem ipsum may be used as a placeholder before the final copy
                is available In publishing and graphic design, Lorem ipsum is a
                placeholder text commonly used to demonstrate the visual form of
                a document or a typeface without relying on meaningful content.
                Lorem ipsum may be used as a placeholder before the final copy
                is availableIn publishing and graphic design, Lorem ipsum is a
                placeholder text commonly used to demonstrate the visual form of
                a document or a typeface without relying on meaningful content.
                Lorem ipsum may be used as a placeholder before the final copy
                is available
              </p>
            </div>
          </Col>
          <Col>
            <div className='my-5'>
              {/* <h5 className='color-theme text-bold mb-4'>Photo Gallery </h5> */}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default About
