import request from '../utils/request'
import { FETCH_TYPES, SERVER_URL } from '../utils/constants'
import { REST_API } from './API_Routes'
// import { encodeQuery } from '../utils/helperFunctions'

export default class HomePageService {
  static async getHomePageData() {
    const response = await request(SERVER_URL + 'homepage')
    return response
  }

  static async subscription(email) {
    const response = await request(SERVER_URL + 'subscription', {
      method: FETCH_TYPES.POST,
      // 'X-CSRF-TOKEN': '{{ csrf_token() }}',
      body: {
        email,
      },
    })
    return response
  }
}
