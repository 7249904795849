import Documents from '../pages/documents/pages/Documents';
import ProductView from '../pages/products/pages/ProductView';
import Products from '../pages/products/pages/Products';
import ProductsByCategory from '../pages/products/pages/ProductsByCategory';


const DocumentsRoutes = [
  {
    path: '/documents',
    component: Documents,
  },
  // {
  //   path: '/documents/view/:id',
  //   component: ProductView,
  // },
];

export default DocumentsRoutes
