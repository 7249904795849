import React from 'react'
import { useLocation } from 'react-router-dom';
import HomeHeader from './HomeHeader'

function Header() {
  const [showMenu, setShowMenu] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    if (showMenu) {
      var dropdown = document.querySelector('.user-menu-btn');
      const handleShowMenu = (event) => {
        console.log('clicked outside user menu...')
        var isClickInside = dropdown?.contains(event.target);
        if (!isClickInside) {
          setShowMenu(false);
        }
      }
      document.addEventListener('click', handleShowMenu);
      return () => {
        document.addEventListener('click', handleShowMenu);
      }
    }
  },[showMenu]);

  return <HomeHeader />
}

export default Header
