import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { CLASSES } from '../../../assets/styles/classes'
import { Card, CardBody, Image } from 'react-bootstrap'
import { IMAGE_URL } from '../../../utils/constants'

function BoardMembers({ data = [] }) {
  return (
    <Container className='pb-5'>
      <Row className={CLASSES.content_center + 'mb-5'}>
        <h5 className='text-center'>Board of members</h5>
        {data.map((member, i) => (
          <Col key={member.id} xl={3} lg={4}  sm={6} className='my-4'>
            <div className='position-relative mb-5'>
              <div
                style={{
                  aspectRatio: 1 / 1,
                  overflow: 'hidden',
                }}
                className={CLASSES.content_center}
              >
                <Image
                  height={'100%'}
                  src={
                    (member.photo_path.startsWith('http')
                      ? member.photo_path
                      : IMAGE_URL + member.photo_path) ||
                    'https://via.placeholder.com/300'
                  }
                  className='pointer'
                  // onClick={() => setActive((x) => !x)}
                />
              </div>
              <div
                style={{
                  // height: '100%',
                  // width: '100%',
                  position: 'absolute',
                  bottom: -40,
                  right: 0,
                  left: 0,
                }}
                className='px-4'
              >
                <Card
                  style={{ borderRadius: 0 }}
                  className='black-shadow-15 border-0'
                >
                  <CardBody>
                    <h6>{member.name}</h6>
                    <div>{member.designation}</div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default BoardMembers
