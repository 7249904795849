// export const SERVER_URL = "https://actualgreen.kurkuri.work/dashboard/";
export const SERVER_URL = "https://myagc24bd.godlyfaces.com/api/";
export const IMAGE_URL = "http://myagc24bd.godlyfaces.com/assets/images/products/";
// export const SERVER_URL = "https://myagc24bd.godlyfaces.com/api/";
// export const IMAGE_URL = "https://myagc24bd.godlyfaces.com/assets/images/products/";

export const Languages = ['English', 'Italian']

export const FETCH_TYPES = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  PUT: 'PUT',
  DELETE: 'DELETE',
}

export const PAGINATION_TYPES = {
  PRODUCTS: 'PRODUCTS',
  SUPPLIERS: 'SUPPLIERS',
  CATEGORIES: 'CATEGORIES',
  PART_MODEL: 'PART_MODEL',
  DDT: 'DDT',
  DDT_PRODUCTS: 'DDT_PRODUCTS',
  RETURN_DDT: 'RETURN_DDT',
  RETURN_DDT_PRODUCTS: 'RETURN_DDT_PRODUCTS',
  TECHNICIANS: 'TECHNICIANS',
  ASSIGNED_PRODUCTS: 'ASSIGNED_PRODUCTS',
  ASSIGNED_TECHNICIAN: 'ASSIGNED_TECHNICIAN',
  ASSIGNED_INTERVENTION: 'ASSIGNED_INTERVENTION',
  CLOSING_CONFIRMATION: 'CLOSING_CONFIRMATION',
}

export const LOADING_NAME = {
  ...PAGINATION_TYPES,
}

export const TABLE_ROWS_PER_PAGE = 10;
export const TABLE_ROWS_PER_PAGE_OPTIONS = [5, 10, 15, 20, 25, 30];

export const FONT_SIZE = {
  table_data: 14,
  table_header: '1rem',
  button: 14,
  input: 14,
  base: 14,
}

export const DOWNLOAD_BUTTON_HEIGHT = 30