import request from '../utils/request'
import { FETCH_TYPES, SERVER_URL } from '../utils/constants'
import { REST_API } from './API_Routes'
// import { encodeQuery } from '../utils/helperFunctions'

export default class ProductsService {
  static async getProducts(query = 1) {
    const response = await request(`${SERVER_URL}products?page=${query}`)
    return response
  }

  // static async subscription(email) {
  //   const response = await request(SERVER_URL + 'subscription', {
  //     method: FETCH_TYPES.POST,
  //     // 'X-CSRF-TOKEN': '{{ csrf_token() }}',
  //     body: {
  //       email,
  //     },
  //   })
  //   return response
  // }
}
