import { ThreeDots } from 'react-loader-spinner'

function LoadingContainer({ children, loading = false }) {
  return (
    <>
      {loading ? (
        <div className='d-flex justify-content-center align-items-center flex-column bg-body' style={{position: 'absolute', width: '100%', height: '100%', zIndex: 1}}>
          <ThreeDots
            visible={true}
            height='80'
            width='80'
            color='#4fa94d'
            radius='9'
            ariaLabel='three-dots-loading'
            wrapperStyle={{}}
            wrapperClass=''
          />
        </div>
      ) : (
        children
      )}
    </>
  )
}

export default LoadingContainer
