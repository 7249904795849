import React, { Component } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { IMAGE_URL } from '../../utils/constants'
import { CLASSES } from '../../assets/styles/classes'

const ZoomView = ({ state = {}, handleState = () => {} }) => {
  const Controls = ({ zoomIn, zoomOut, resetTransform }) => (
    <div className='d-flex align-items-center justify-content-center zoom-controller'>
      <button className='zoom-button' onClick={() => zoomIn()}>
        +
      </button>
      <button className='zoom-button' onClick={() => zoomOut()}>
        -
      </button>
      {/* <button onClick={() => resetTransform()}>x</button> */}
    </div>
  )

  return (
    <Modal
      show={state.show_modal}
      onHide={handleState}
      size='xl'
      // fullscreen
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        {state.title && (
          <Modal.Title id='contained-modal-title-vcenter' className='text-center w-100'>
            <b>{state.title}</b>
          </Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body className={CLASSES.content_center}>
        <TransformWrapper centerOnInit>
          {(utils) => (
            <React.Fragment>
              <TransformComponent
                wrapperStyle={{ width: '100%' }}
                wrapperClass='w-100'
              >
                <img
                  width={'100%'}
                  src={state.modal_images}
                  alt='product image'
                  className='product_modal_images'
                />
              </TransformComponent>
              {/* <Controls {...utils} /> */}
            </React.Fragment>
          )}
        </TransformWrapper>
      </Modal.Body>
    </Modal>
  )
}

export default ZoomView
