import React, { Component } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from '../pages/home/pages'
import About from '../pages/About'
import Footer from '../components/footer/Footer'
import FAQ from '../pages/FAQ'
import ContactUs from '../pages/ContactUs'
import Header from '../components/header'
import TermsOfUSe from '../pages/TermsOfUses'
import Privacy from '../pages/Privacy'
import PageNotFound from '../pages/PageNotFound'
import ProductsRoutes from './Products'
import DocumentsRoutes from './DocumentsRoutes'

// const Login = lazy(() => import('../pages/login/LoginPage'));

class RoutersList extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div className='min-vh-100 d-flex flex-column bg-white'>
        {/* overflow-hidden */}
        <BrowserRouter basename='/'>
          <Header />
          <Routes>
            <Route path='/' Component={Home} />
            {ProductsRoutes.map((item) => (
              <Route
                key={item.path}
                path={item.path}
                Component={item.component}
              />
            ))}
             {DocumentsRoutes.map((item) => (
              <Route
                key={item.path}
                path={item.path}
                Component={item.component}
              />
            ))}
            <Route path='/about' Component={About} />
            <Route path='/contact' Component={ContactUs} />
            <Route path='/faq' Component={FAQ} />
            <Route path='/terms-of-uses' Component={TermsOfUSe} />
            <Route path='/privacy' Component={Privacy} />
            <Route path='/*' Component={PageNotFound} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    )
  }
}

export default RoutersList
